import { Component, ElementRef, Input, ViewChild, inject } from '@angular/core';

import { ChatPanelComponent } from '../chat-bot/chat-panel/chat-panel.component';
import { ChatBotComponent } from '../chat-bot/chat-bot.component';
import { BriefCreatorComponent } from '../brief-creator/brief-creator.component';
import { SuggestionsPanelComponent } from '../suggestions-panel/suggestions-panel.component';
import { OutlineComponent } from '../outline/outline.component';
import { AssistantForm, AssistantResponseDetails, UnusedOptions } from '../chat-bot/models/brief-assistant-model';
import { CommonModule, NgIf, JsonPipe } from '@angular/common';
import { SuggestionUpdate } from '../chat-bot/models/suggestions-update';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import Swal from 'sweetalert2';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { AssistantPreSelection } from '../chat-bot/models/assistant-pre-selection-model';
import { AssistantManagerService } from '../chat-bot/services/assistant-manager-service.service';
import { SpinnerHandlerService } from 'src/app/_shared/services/spinner-handler.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { VersionControlData, VersionControlResponse } from '../chat-bot/models/version-control-model';
import { AuthService } from 'src/app/_shared/services/auth-service';
import { BriefPreSelectionComponent } from "../brief-pre-selection/brief-pre-selection.component";
import { Suggestion } from '../chat-bot/models/scoring';
import { NgxSpinnerModule } from 'ngx-spinner';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';



@Component({
    selector: 'app-wc-assistants-dashboard',
    standalone: true,
    templateUrl: './wc-assistants-dashboard.component.html',
    styleUrl: './wc-assistants-dashboard.component.sass',
    imports: [ChatPanelComponent, ChatBotComponent, BriefCreatorComponent, SuggestionsPanelComponent, OutlineComponent, NgIf, CommonModule, MatIconModule, MatSidenavModule, MatTooltipModule, MatSnackBarModule, BriefPreSelectionComponent, NgxSpinnerModule]
})
export class WcAssistantsDashboardComponent {
  frontendServURL = environment.frontendServURL;
  assistantManagerService = inject(AssistantManagerService);
  spinnerTemplate = `<img src="${environment.frontendServURL}/assets/img/stellar-logov2.svg"/>`;
  @Input() token: string;
  @Input() isPreviousBrief: boolean = false;
  @ViewChild('suggestionsDrawer') suggestionsDrawer: MatSidenav;
  @ViewChild(ChatBotComponent) chatBotComponent: ChatBotComponent;
  @ViewChild(BriefCreatorComponent) briefCreatorComponent: BriefCreatorComponent;
  @ViewChild(SuggestionsPanelComponent) suggestionsPanelComponent: SuggestionsPanelComponent;
  @ViewChild(BriefPreSelectionComponent) briefPreSelectionComponent: BriefPreSelectionComponent;
  @ViewChild(OutlineComponent) outlineComponent: OutlineComponent;
  titleForSuggestions: string = '';
  PrimaryKeywordsForSuggestions: string = '';
  metaDescriptionForSuggestions: string = '';
  unusedOptions: UnusedOptions;
  currentStep: string = '';
  currentStepSuggestions: string[] = [];
  isSuggestionsPanelOpen = false;
  currentSelectedSectionId: string = '';
  // TO_DO improve that outline
  outlineItems: any[] = []; 
  titleAdded = false;
  fieldAdded = false;
  primaryKeywordAdded = false;
  secondaryKeywordsAdded = false;
  metaDescriptionAdded = false;
  wordCountAdded = false;
  toneofVoiceCountAdded = false;
  contentGoalCountAdded = false;
  povCountAdded = false;
  businessObjectiveAdded = false;
  ctaAdded = false;
  internalLinksAdded = false;
  keyPointsAdded = false;
  thingsToAvoidAdded = false;
  audienceInsightsAdded = false;
  specialInstructionsAdded = false;
  serpAnalysisAdded = false;
  differentiationOpportunitiesisAdded = false;
  contentOutlineisAdded = false;
  nextStepsOpen: boolean = false;
  selectedSectionIdFromOutline: string | null = null;
  previousFormData: AssistantForm | null = null;
  loadingText: string = '';
  isLoadingSuggestions = false;
  showAlternativeOptions = false;
  secondMessageSubscription: Subscription;
  responseCount = 0;
  chatSectionId: string = '';
  versionControlData: VersionControlData[];
  canToggleSuggestions: boolean = true;
  showPreSelectionsBrief: boolean = true;
  displayAdditionalContent: boolean = true;
  private subscription: Subscription = new Subscription();
  sessionId: string | null = null;
  pendingHistoryData: any[] | null = null;
  cdr: any;
  

  constructor(private authSer:AuthService, private snackBar: MatSnackBar,private spinnerHandler: SpinnerHandlerService) {
   
  }

  ngOnInit(): void {
    console.log("AI TOOLS TOKEN1", this.token);
    if (this.token) {
      this.subscription.add(
        this.authSer.exLogin(this.token).subscribe({
          next: (res) => {
            // after successful login, fetch profile_slug
            this.getProfileSlug();
            this.cdr.detectChanges();  // Detect changes after successful login
          },
          error: (error) => {
            console.error("Login failed or token invalid", error);
          }
        })
      );
    }
  }

  private getProfileSlug(): void {
    this.subscription.add(
      this.assistantManagerService.getProfileSlug(this.token).subscribe({
        next: (response) => {
        },
        error: (error) => {
          console.error('Error fetching profile slug:', error);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe(); 
  }

   handleSessionIdUpdated(sessionId: string) {
    this.chatSectionId = sessionId;
  }

  processVersionControlData(data: VersionControlResponse): void {
    this.versionControlData = data.response.reverse(); // version control data
    //console.log("Version Control Data",data.response);
  }

  handleAssistantResponse(assistantData: AssistantResponseDetails): void {
    console.log("isPreviousBrief", this.nextStepsOpen, this.showPreSelectionsBrief,this.isPreviousBrief);
    if (!(this.nextStepsOpen && !this.showPreSelectionsBrief && this.isPreviousBrief)) {
        this.compareFormDataAndShowSnackbars(this.previousFormData, assistantData.form);
    }
    this.previousFormData = { ...assistantData.form };
    if (this.briefCreatorComponent) {
      this.briefCreatorComponent.updateFormData(assistantData.form);
    }

    if (assistantData.current_step && assistantData.options) {
        this.currentStep = assistantData.current_step;
        this.currentStepSuggestions = assistantData.options;
        console.log("Dashboard",this.currentStep, this.currentStepSuggestions);
    }

    // update the session custom name with the title value
    if (assistantData.current_step === 'primary_keyword' && assistantData.form.title) {
      const sessionId = assistantData.session_id;
      const customName = assistantData.form.title;
      console.log("Custom Name update",sessionId,customName);
      this.assistantManagerService.updateSessionName(sessionId, customName).subscribe({
        next: (response) => {
          console.log('Session name updated successfully:', response);
          if (this.briefPreSelectionComponent){
            this.briefPreSelectionComponent.initializeChatPanel();
          }else {
            console.error('briefPreSelectionComponent is not yet loaded');
          }
        },
        error: (error) => {
          console.error('Error updating session name:', error);
        }
      });
    }
    
    
    // if it is not previous brief, call assistants to load the outline and scoring
    if (assistantData.current_step) {
      if (!this.nextStepsOpen && assistantData.current_step === "completed")  {
       // if (!this.nextStepsOpen && (assistantData.current_step === "secondary_keywords" || assistantData.current_step === "secondary-keywords")) { //Julia
       // update the WebSocket URL and establish a new connection
        this.assistantManagerService.updateWebSocketUrl('asst_EKdb2uEGVpFGqJVPgLeT55g3', false);
        this.assistantManagerService.connect();
  
        // display the spinner before sending the message
        this.handleLoadingText("Generating content outline and loading form");

        this.assistantManagerService.getSessionData(this.chatSectionId).subscribe({
          next: (data: VersionControlResponse) => {
            console.log('Data received from the service:', data);
            this.processVersionControlData(data);
          },
          error: (error) => {
            console.error('Failed to retrieve session data:', error);
          }
        });
  
        // checks if the connection has been established before sending the message
        const checkConnection = setInterval(() => {
          if (this.assistantManagerService.isConnected()) {
            clearInterval(checkConnection); // clears the interval when the connection is established
            
            const messageToSend = JSON.stringify(assistantData.form);
            this.assistantManagerService.sendMessage(messageToSend);
          }
        }, 100);
        
        const messagesSubscription = this.assistantManagerService.messages$.subscribe({
          next: (message) => {
            if (message.status === 'completed') {
           
              const contentOutline = message.response;
              console.log("TEST CONTENTE OUTLINE 1",contentOutline);
              this.assistantManagerService.updateWebSocketUrl('asst_lBkcpQMGs8yYy6l6ElEOGWCF', false);
              this.assistantManagerService.connect();

              const checkSecondConnection = setInterval(() => {
                if (this.assistantManagerService.isConnected()) {
                  clearInterval(checkSecondConnection);
                  
                  const secondMessageToSend = JSON.stringify(assistantData.form);
                  this.assistantManagerService.sendMessage(secondMessageToSend);
                  this.listenToSecondMessage(secondMessageToSend, 0);
                }
              }, 100);
               // content outline     
              if (contentOutline && !this.contentOutlineisAdded) {
               
                console.log("COntent Outline HTML",contentOutline.outline_html);
                // save content outline
                this.submitContentOutine(this.chatSectionId, contentOutline.outline_html);

                const contentOutlineisItem = { text: 'Content Outline', selected: false, hover: false, slug: 'content-outline' };
                this.outlineComponent.addOutlineItem(contentOutlineisItem);
                this.contentOutlineisAdded = true;
              }
              // update BriefCreatorComponent with new content
                if (this.briefCreatorComponent) {
                  this.briefCreatorComponent.updateContentOutline(contentOutline);
                }
              // hide the spinner and show the success message
              this.spinnerHandler.handleRequest('minus');
              Swal.fire({
                title: 'Success!',
                text: 'Form generated successfully',
                icon: 'success',
                confirmButtonText: 'OK',
                timer: 1500, 
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer);
                  toast.addEventListener('mouseleave', Swal.resumeTimer);
                },
                confirmButtonColor: '#0067C4',
              }).then(() => {
                // TO-DO - check pass to second step
                this.nextStepsOpen = true;
              });
            }
            messagesSubscription.unsubscribe();
          },
          error: (err) => {
            console.error('WebSocket Error:', err);
            this.spinnerHandler.handleRequest('minus');
            messagesSubscription.unsubscribe();
          }
        });
      } 
    }  
    
    // TO-DO Improve that
    if (this.suggestionsPanelComponent) {
      this.suggestionsPanelComponent.selectedTitle = assistantData.form.title || '';
      this.suggestionsPanelComponent.reciveSelectedPrimaryKeywords = assistantData.form.primary_keyword || '';
      this.suggestionsPanelComponent.reciveSelectedSecondaryKeywords = assistantData.form.secondary_keywords || [];
      this.suggestionsPanelComponent.reciveSelectedMetaDescription = assistantData.form.meta_description || '';
      this.suggestionsPanelComponent.reciveSelectedWordCount = assistantData.form.word_count || '';
      this.suggestionsPanelComponent.reciveSelectedToneOfVoice = assistantData.form.voice_and_tone || '';
      this.suggestionsPanelComponent.reciveSelectedContentGoal = assistantData.form.content_goal || '';
      this.suggestionsPanelComponent.reciveSelectedPov = assistantData.form.pov || '';
      this.suggestionsPanelComponent.reciveSelectedBusinessObjective = assistantData.form.business_objective || '';
      this.suggestionsPanelComponent.reciveSelectedCta = assistantData.form.call_to_action || '';
      this.suggestionsPanelComponent.reciveSelectedInternalLinks = assistantData.form.internal_links || [];
      this.suggestionsPanelComponent.reciveSelectedKeyPoints = assistantData.form.key_points_to_emphasize || [];
      this.suggestionsPanelComponent.reciveSelectedThingsToAvoid = assistantData.form.things_to_avoid || [];
      this.suggestionsPanelComponent.reciveSelectedAudience = assistantData.form.target_audience || '';
      this.suggestionsPanelComponent.reciveSelectedSpecialInstructions = assistantData.form.special_instructions || [];
      this.suggestionsPanelComponent.reciveSelectedSerpAnalysis = assistantData.form.serp_analysis || '';
      this.suggestionsPanelComponent.reciveSelectedOpportunities = assistantData.form.differentiation_opportunities || [];
      

      // Title
      if (assistantData.form.title && !this.titleAdded) {
        const titleItem = { text: 'Title', selected: false, hover: false, slug: 'title' };
        this.outlineComponent.addOutlineItem(titleItem);
        this.titleAdded = true;
      }

      // Primary Keyword
      if (assistantData.form.primary_keyword && !this.primaryKeywordAdded) {
        const primaryKeywordItem = { text: 'Primary Keyword', selected: false, hover: false, slug: 'primary-keyword' };
        this.outlineComponent.addOutlineItem(primaryKeywordItem);
        this.primaryKeywordAdded = true;
      }
      // Secondary Keywords
      if (assistantData.form.secondary_keywords && assistantData.form.secondary_keywords.length > 0 && !this.secondaryKeywordsAdded) {
        const secondaryKeywordsItem = { text: 'Secondary Keywords', selected: false, hover: false, slug: 'secondary-keywords' };
        this.outlineComponent.addOutlineItem(secondaryKeywordsItem);
        this.secondaryKeywordsAdded = true;
      }
      // Meta Description
      if (assistantData.form.meta_description && !this.metaDescriptionAdded) {
        const metaDescriptionItem = { text: 'Meta Description', selected: false, hover: false, slug: 'meta-description'};
        this.outlineComponent.addOutlineItem(metaDescriptionItem);
        this.metaDescriptionAdded = true;
      }
      // Word Count
      if (assistantData.form.word_count && !this.wordCountAdded) {
        const wordCountItem = { text: 'Word Count', selected: false, hover: false, slug: 'word-count'};
        this.outlineComponent.addOutlineItem(wordCountItem);
        this.wordCountAdded = true;
      }

      // Tone of voice
      if (assistantData.form.voice_and_tone && !this.toneofVoiceCountAdded) {
        const toneCountItem = { text: 'Voice and Tone', selected: false, hover: false, slug: 'tone-of-voice' };
        this.outlineComponent.addOutlineItem(toneCountItem);
        this.toneofVoiceCountAdded = true;
      }

      // Content Goal of voice
      if (assistantData.form.content_goal && !this.contentGoalCountAdded) {
        const contentGoalCountItem = { text: 'Content Goal', selected: false, hover: false, slug: 'content-goal' };
        this.outlineComponent.addOutlineItem(contentGoalCountItem);
        this.contentGoalCountAdded = true;
      }

      // pov
      if (assistantData.form.pov && !this.povCountAdded) {
        const povCountItem = { text: 'Point of View', selected: false, hover: false, slug: 'point-of-view' };
        this.outlineComponent.addOutlineItem(povCountItem);
        this.povCountAdded = true;
      }

      // business objective
      if (assistantData.form.business_objective && !this.businessObjectiveAdded) {
        const businessObjectiveCountItem = { text: 'Business Objective', selected: false, hover: false, slug: 'business-objective' };
        this.outlineComponent.addOutlineItem(businessObjectiveCountItem);
        this.businessObjectiveAdded = true;
      }

      // Call to Action (CTA)
      if (assistantData.form.call_to_action && !this.ctaAdded) {
        const ctaItem = { text: 'Call to Action', selected: false, hover: false, slug: 'cta' };
        this.outlineComponent.addOutlineItem(ctaItem);
        this.ctaAdded = true;
      }

      // Internal Links     
      if (assistantData.form.internal_links && assistantData.form.internal_links.length > 0 && !this.internalLinksAdded) {
        const internalLinksItem = { text: 'Links to Include', selected: false, hover: false, slug: 'internal-links' };
        this.outlineComponent.addOutlineItem(internalLinksItem);
        this.internalLinksAdded = true;
      }

      // Key Points     
      if (assistantData.form.key_points_to_emphasize && assistantData.form.key_points_to_emphasize.length > 0 && !this.keyPointsAdded) {
        const keyPointsItem = { text: 'Key Points', selected: false, hover: false, slug: 'key-points' };
        this.outlineComponent.addOutlineItem(keyPointsItem);
        this.keyPointsAdded = true;
      }

      // Things to avoid  
      if (assistantData.form.things_to_avoid && assistantData.form.things_to_avoid.length > 0 && !this.thingsToAvoidAdded) {
        const thingToAvoidItem = { text: 'Thing to Avoid', selected: false, hover: false, slug: 'things-to-avoid' };
        this.outlineComponent.addOutlineItem(thingToAvoidItem);
        this.thingsToAvoidAdded = true;
      }


      // Audience Insights
      if (assistantData.form.target_audience && !this.audienceInsightsAdded) {
        const audienceInsightsItem = { text: 'Target Audience', selected: false, hover: false, slug: 'audience-insights' };
        this.outlineComponent.addOutlineItem(audienceInsightsItem);
        this.audienceInsightsAdded = true;
      }

      // Special Instructions     
      if (assistantData.form.special_instructions && !this.specialInstructionsAdded && assistantData.form.special_instructions.length > 0) {
        const SpecialInstructionsItem = { text: 'Special Instructions', selected: false, hover: false, slug: 'special-instructions' };
        this.outlineComponent.addOutlineItem(SpecialInstructionsItem);
        this.specialInstructionsAdded = true;
      }

      // Serp analysis Instructions     
      if (assistantData.form.serp_analysis && !this.serpAnalysisAdded) {
        const serpAnalysisItem = { text: 'Serp Analysis', selected: false, hover: false, slug: 'serp-analysis' };
        this.outlineComponent.addOutlineItem(serpAnalysisItem);
        this.serpAnalysisAdded = true;
      }

      // differentiation_opportunities     
      if (assistantData.form.differentiation_opportunities && !this.differentiationOpportunitiesisAdded && assistantData.form.differentiation_opportunities.length > 0) {
        const differentiationOpportunitiesisItem = { text: 'Differentiation Opportunities', selected: false, hover: false, slug: 'differentiation-opportunities' };
        this.outlineComponent.addOutlineItem(differentiationOpportunitiesisItem);
        this.differentiationOpportunitiesisAdded = true;
      }

    }
  }
  listenToSecondMessage(messageToSend: string, attemptCount: number = 0) {
    this.isLoadingSuggestions = true;
    this.snackBar.open("Generating scoring, you will be notified when it is ready", "Close", { duration: 3000 });
  
    this.secondMessageSubscription = this.assistantManagerService.messages$.subscribe({
      next: (secondMessage) => {
        if (secondMessage.status === 'completed') {
          console.log("SECOND MSG SesionID",this.chatSectionId);
          const scoring = secondMessage.response.scoring;
          const suggestions = secondMessage.response.suggestions;
          this.submitScoringFeedback(this.chatSectionId, scoring, suggestions);
          this.outlineComponent.scoring = secondMessage.response.scoring;
          this.outlineComponent.suggestions = secondMessage.response.suggestions;
          //this.snackBar.open("Scoring and suggestions generated successfully", "Close", { duration: 3000 });
          this.secondMessageSubscription.unsubscribe();
        }
      },
      error: (err) => {
        console.error('Error in second WebSocket message:', err);
        if (attemptCount < 3) { 
          setTimeout(() => {
            this.assistantManagerService.sendMessage(messageToSend);
            this.listenToSecondMessage(messageToSend, attemptCount + 1); 
          }, 1000);
        } else {
          this.isLoadingSuggestions = false;
          this.snackBar.open("An error occurred while generating scoring and suggestions", "Close", { duration: 3000 });
          this.secondMessageSubscription.unsubscribe();
        }
      }
    });
  }
  
  
  
  onRefreshScoringRequested(): void {
    this.isLoadingSuggestions = true;
  
    if (this.assistantManagerService.isConnected()) {
      this.sendFormData();
    } else {
      const connectionSubscription = this.assistantManagerService.connectionOpened$.subscribe(() => {
        this.sendFormData();
        connectionSubscription.unsubscribe(); 
      });
      this.assistantManagerService.updateWebSocketUrl('asst_lBkcpQMGs8yYy6l6ElEOGWCF', false);
      this.assistantManagerService.connect();
    }
  }
  
  private sendFormData(): void {
    const updatedFormData = this.briefCreatorComponent.getCurrentFormData();
    const messageToSend = JSON.stringify(updatedFormData);
    this.assistantManagerService.sendMessage(messageToSend);
  
    const subscription = this.assistantManagerService.messages$.subscribe({
      next: (response) => {
        if (response && response.status === 'completed') {
          // emits a signal to indicate that the suggestions have been reloaded
          console.log("Emit isReloadingSuggestions",response.response.scoring, response.response.suggestions);
          // update the OutlineComponent with the score and suggestions received
          const scoring = response.response.scoring;
          const suggestions = response.response.suggestions;
          this.updateOutlineComponent(scoring, suggestions);
          console.log("Feedback SessionID");
          this.submitScoringFeedback(this.sessionId, scoring, suggestions); 
          // display spinner loading suggestions outline component
          this.outlineComponent.isReloadingSuggestions = true;          
          this.snackBar.open("Scoring and suggestions generated successfully", "Close", { duration: 3000 });
          subscription.unsubscribe();
        }
      },
      error: (err) => {
        console.error("Erro ao receber a mensagem:", err);
        this.snackBar.open("An error occurred while generating scoring and suggestions.", "Close", { duration: 3000 });
        this.outlineComponent.isReloadingSuggestions = false;
        subscription.unsubscribe();
      }
    });
  }

  private updateOutlineComponent(scoring: number, suggestions: Suggestion[]): void {
    console.log("updateOutlineComponent",scoring,suggestions);
    // updates scoring and suggestions in OutlineComponent
    this.outlineComponent.scoring = scoring;
    this.outlineComponent.suggestions = suggestions;
  
    // sets isReloadingSuggestions to false to indicate that the data has been loaded
    this.outlineComponent.isReloadingSuggestions = false;
    this.outlineComponent.triggerChangeDetection();
  }
    
  subscribeToResponses(messageToSend: string, attemptCount: number): void {
    console.log("subscribeToResponses",messageToSend);
    const responseSubscription = this.assistantManagerService.messages$.subscribe({
      next: (response) => {
        console.log("subscribeToResponses response",response);
        if (response && response.status === 'completed') {
          this.responseCount++;
          if (this.responseCount > 0 && this.responseCount % 2 === 0) {
            this.outlineComponent.isReloadingSuggestions = false;
            this.snackBar.open("Scoring and suggestions generated successfully", "Close", { duration: 3000 });
          }
          responseSubscription.unsubscribe();
        }
      },
      error: (err) => {
        console.error("Error receiving WebSocket message:", err);
        if (attemptCount < 3) { 
          this.assistantManagerService.sendMessage(messageToSend);
          this.subscribeToResponses(messageToSend, attemptCount + 1); 
        } else {
          this.isLoadingSuggestions = false;
          this.snackBar.open("An error occurred while generating scoring and suggestions", "Close", { duration: 3000 });
          responseSubscription.unsubscribe();
        }
      }
    });
  }

  sendGenerateOutlineMessage() {
    if (this.chatBotComponent) {
      this.chatBotComponent.sendGenerateOutlineMessage();
    }
  }

  onTitleUpdated(newTitle: string): void {
    this.titleForSuggestions = newTitle;
  }

  onMainKeywordUpdated(newPrimaryKeyword: string): void {
    this.PrimaryKeywordsForSuggestions = newPrimaryKeyword;
  }

  onMetaDescriptionUpdated(newMetaDescription: string): void {
    this.metaDescriptionForSuggestions = newMetaDescription;
  }

  onUpdateRequested(update: SuggestionUpdate): void {
    if (this.chatBotComponent) {
      this.chatBotComponent.requestUpdate(update);
    } 
  
    if (this.briefCreatorComponent) {
      this.briefCreatorComponent.updateValue(update.type, update.value, update.updateType);
    }
  
    if (this.suggestionsPanelComponent) {
      this.suggestionsPanelComponent.setLoading(update.type, true);
    }
  }

  handleSectionSelected(sectionId: string) {
    this.currentSelectedSectionId = sectionId;
    if (this.outlineComponent) {
      this.outlineComponent.highlightOutlineItem(sectionId);
    }
  }
  toggleFocusStyle(enable: boolean): void {
    const elements = document.querySelectorAll('.tinymce-heading');
    elements.forEach(element => {
      if (enable) {
        element.classList.add('focused');
      } else {
        element.classList.remove('focused');
      }
    });
  }
  

  animDisabled: boolean = false;

  toggleSuggestionsPanel(currentSelectedSectionId?: string): void {
    this.animDisabled = true;
  
    if (this.canToggleSuggestions && !this.isSuggestionsPanelOpen) {
      console.log(" IF toggleSuggestionsPanel");
      this.isSuggestionsPanelOpen = true;
      this.suggestionsDrawer.open();
      this.briefCreatorComponent.applyHighlight(currentSelectedSectionId);
      setTimeout(() => {
        this.animDisabled = false;
        this.briefCreatorComponent.toggleFocusClass(true);
      }, 300);
    } else if (this.isSuggestionsPanelOpen) {
      this.briefCreatorComponent.clearHighlight(currentSelectedSectionId);
      setTimeout(() => {
        this.animDisabled = false;
        this.briefCreatorComponent.toggleFocusClass(false);
      }, 300);
    }
  }  
  
  hideSuggestionsPanel(currentSelectedSectionId?: string): void {
    //console.log("hideSuggestionsPanel",this.canToggleSuggestions,"PanelOpen",this.isSuggestionsPanelOpen);
    this.isSuggestionsPanelOpen = false;
    this.canToggleSuggestions = false; // disable automatic opening
    this.suggestionsDrawer.close();
    setTimeout(() => {
      this.animDisabled = false;
      this.briefCreatorComponent.toggleFocusClass(false);
      this.briefCreatorComponent.clearHighlight(currentSelectedSectionId);
    }, 300);
  }
  
  openAlternativesPanel(currentSelectedSectionId?: string): void {
    this.canToggleSuggestions = true;  // reactivates automatic opening
    this.toggleSuggestionsPanel(currentSelectedSectionId);
  }



  handleOutlineSelection(selectedSectionId: string) {
    if (selectedSectionId !== 'brand' && selectedSectionId !== 'publish-url' && selectedSectionId !== 'content-type' && selectedSectionId !== 'vertical') {
      this.currentSelectedSectionId = selectedSectionId;
    }else{
      this.currentSelectedSectionId = null
      this.isSuggestionsPanelOpen = false
    }

    if (this.briefCreatorComponent) {
      this.selectedSectionIdFromOutline = selectedSectionId;
    }
  }  


  compareFormDataAndShowSnackbars(previousFormData: AssistantForm | null, currentFormData: AssistantForm): void {
    const keysOfInterest = Object.keys(currentFormData) as (keyof AssistantForm)[];
  
    keysOfInterest.forEach(key => {
      const currentValue = currentFormData[key];
      const previousValue = previousFormData ? previousFormData[key] : undefined;
  
      let valueChanged = false;
  
      // for arrays, check if the previous value was undefined or an empty array, and the current value is not empty
      if (Array.isArray(currentValue)) {
        valueChanged = (!previousValue || (Array.isArray(previousValue) && previousValue.length === 0)) && currentValue.length > 0;
      } 
      // for non-array values, checks whether the previous value was empty (including undefined) and the current value is not empty
      else {
        valueChanged = (!previousValue || previousValue === '') && currentValue !== '' && currentValue !== undefined && currentValue !== null;
      }
  
      if (valueChanged) {
        const formattedKey = key.replace(/_/g, ' ');
        const message = `${this.capitalizeFirstLetter(formattedKey)} added successfully`;
  
        this.snackBar.open(message, 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: 'form-snackbar'
        });
      }
    });
  }
  
  arraysAreEqual(array1: any[], array2: any[]): boolean {
    return array1.length === array2.length && array1.every((element, index) => element === array2[index]);
  }
  
  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  handlePreSelectionAssistant(preSelectionData: AssistantPreSelection) {
    console.log("handlePreSelectionAssistant");
    if (this.briefCreatorComponent) {
      this.briefCreatorComponent.reciveAssistantPreSelection(preSelectionData);
    }
  
    // adding pre-selection items to the outline
  
    if (preSelectionData.brand) {
      const brandItem = { text: 'Brand', selected: false, hover: false, slug: 'brand' };
      this.outlineComponent.addOutlineItem(brandItem);
    }
  
    if (preSelectionData.publishUrl) {
      const publishUrlItem = { text: 'Publish URL', selected: false, hover: false, slug: 'publish-url' };
      this.outlineComponent.addOutlineItem(publishUrlItem);
    }
  
    if (preSelectionData.contentType) {
      const contentTypeItem = { text: 'Content Type', selected: false, hover: false, slug: 'content-type' };
      this.outlineComponent.addOutlineItem(contentTypeItem);
    }

    if (preSelectionData.vertical) {
      const verticalItem = { text: 'Vertical', selected: false, hover: false, slug: 'vertical' };
      this.outlineComponent.addOutlineItem(verticalItem);
    }
    //this.showPreSelectionsBrief = false;
    this.displayAdditionalContent = false;
    console.log(" showPreSelectionsBrief- ShowPreselection",this.showPreSelectionsBrief," showPreSelectionsBrief - NextSteps",this.nextStepsOpen, " showPreSelectionsBrief - displayAdditionalContent", this.displayAdditionalContent);
  }

  handleAssistantChosen(assistantKey: string) {
    if (this.chatBotComponent) {
      console.log("handleAssistantChosen",assistantKey);
      this.chatBotComponent.handleAssistantSelection(assistantKey);
    } 
    console.log(" handleAssistantChosen- ShowPreselection",this.showPreSelectionsBrief," handleAssistantChosen - NextSteps",this.nextStepsOpen, " handleAssistantChosen - displayAdditionalContent", this.displayAdditionalContent);
  }

  handleSessionIdLoaded(sessionId: string): void {
    console.log("Session ID received in AssistantDashboard:", sessionId);
    this.sessionId = sessionId;

    // if there is pending data, process now
    if (this.pendingHistoryData) {
      this.processChatHistory(this.pendingHistoryData);
      this.pendingHistoryData = null;
    }
  }

  onChatHistoryLoaded(historyData: any[]): void {
    if (this.sessionId) {
      this.processChatHistory(historyData);
    } else {
      // temporarily stores until the sessionId is received
      this.pendingHistoryData = historyData;
    }
  }

  private processChatHistory(historyData: any[]): void {
    let lastBotMessageForm = null;
    let threadIdSet = new Set();
    let messagesToRemove = new Set();
    let firstThreadId = null;

    // identify and mark messages for removal
    for (let i = 0; i < historyData.length; i++) {
      const message = historyData[i];

      if (message.type === 'bot') {
        const currentThreadId = message.message.thread_id;

        if (!firstThreadId) {
          firstThreadId = currentThreadId;
        }

        if (!threadIdSet.has(currentThreadId) && currentThreadId !== firstThreadId) {
          threadIdSet.add(currentThreadId);
          messagesToRemove.add(i);

          for (let j = i - 1; j >= 0; j--) {
            if (historyData[j].type === 'user') {
              messagesToRemove.add(j);
              break;
            }
          }
        }
      }
    }

    // filter messages that should not be removed
    historyData = historyData.filter((_, index) => !messagesToRemove.has(index));

    for (let i = historyData.length - 1; i >= 0; i--) {
      const message = historyData[i];
      if (message.type === 'bot' && message.message.form) {
        lastBotMessageForm = message.message.form;
        break;
      }
    }

    if (lastBotMessageForm) {
      if (lastBotMessageForm.meta_description && lastBotMessageForm.meta_description.trim() !== '') {
        // completed previou brief
        this.loadingText = `Starting connection and loading previous brief`;
        this.spinnerHandler.handleRequest('plus');
        this.nextStepsOpen = true;
        this.showPreSelectionsBrief = false;
        this.chatBotComponent.loadChatHistoryData(historyData, true, this.sessionId); 
        console.log("IF lastBotMessageForm", this.nextStepsOpen, this.showPreSelectionsBrief, this.displayAdditionalContent);
      } else {
        // previou brief in progress
        this.chatBotComponent.loadChatHistoryData(historyData, false, this.sessionId);
        this.loadingText = `Starting connection and loading previous brief`;
        this.spinnerHandler.handleRequest('plus');
        this.showPreSelectionsBrief = true;
        this.displayAdditionalContent = false;
        console.log("Else 1 lastBotMessageForm", this.nextStepsOpen, this.showPreSelectionsBrief, this.displayAdditionalContent);
      }
    } else {
      this.showPreSelectionsBrief = false;
      console.log("Else 2 lastBotMessageForm", this.nextStepsOpen, this.showPreSelectionsBrief);
    }

    historyData.forEach(message => {
      if (message.type === 'bot' && message.message.options) {
        const step = message.message.current_step;
        const options = message.message.options;

        switch (step) {
          case 'title':
            this.suggestionsPanelComponent.savedTitles = options;
            break;
          case 'keyword_step_primary':
          case 'main_keyword':
          case 'primary_keyword':
            this.suggestionsPanelComponent.savedPrimaryKeywords = options;
            break;
          case 'keyword_step_secondary':
          case 'secondary_keyword':
          case 'secondary_keywords':
            this.suggestionsPanelComponent.savedSecondaryKeywords = options;
            break;
          case 'meta_description':
            this.suggestionsPanelComponent.savedMetaDescription = options;
            break;
          case 'word_count':
            this.suggestionsPanelComponent.savedWordCount = options;
            break;
          case 'voice_and_tone':
          case 'tone_of_voice':
            this.suggestionsPanelComponent.savedToneOfVoice = options;
            break;
          case 'content_goal':
            this.suggestionsPanelComponent.savedContentGoal = options;
            break;
          case 'point_of_view':
            this.suggestionsPanelComponent.savedPov = options;
            break;
          case 'business_objective':
            this.suggestionsPanelComponent.savedBusinessObjective = options;
            break;
            case 'cta':
            case 'call_to_action':
            this.suggestionsPanelComponent.saveddCta = options;
            break;
          case 'internal_links':
            this.suggestionsPanelComponent.savedInternalLinks = options;
            break;
          case 'audience_insights':
          case 'target_audience':
            this.suggestionsPanelComponent.savedAudienceInsights = options;
            break;
          case 'special_instructions':
            this.suggestionsPanelComponent.savedSpecialInstructions = options;
            break;
          case 'key_points_to_emphasize':
            this.suggestionsPanelComponent.savedKeyPoints = options;
            break;
          case 'things_to_avoid':
            this.suggestionsPanelComponent.savedThingsToAvoid = options;
            break;
          case 'serp_analysis':
            this.suggestionsPanelComponent.savedSerpAnalysis = options;
            break;
          case 'differentiation_opportunities':
            this.suggestionsPanelComponent.savedOpportunities = options;
            break;
          default:
            console.log("No such step to update:", step);
            break;
        }
      }
    });
  }

  onScoringFeedbacksLoaded(scoringFeedbacksData: any): void {  
    if (scoringFeedbacksData.feedbacks && scoringFeedbacksData.feedbacks.length > 0) {
      const lastFeedback = scoringFeedbacksData.feedbacks[scoringFeedbacksData.feedbacks.length - 1];
      this.outlineComponent.scoring = lastFeedback.scoring;
      this.outlineComponent.suggestions = lastFeedback.suggestions;
    } else {
      console.error("No scoring feedbacks available or the data is not in the expected format.");
    }
  }

  onContentOutlineLoaded(contentOutlineData: any): void {
  
    if (contentOutlineData && contentOutlineData.length > 0) {
      if (this.briefCreatorComponent) {
        this.briefCreatorComponent.loadPreviounContentOutline(contentOutlineData);
      }
      const contentOutlineisItem = { text: 'Content Outline', selected: false, hover: false, slug: 'content-outline' };
      this.outlineComponent.addOutlineItem(contentOutlineisItem);
    } else {
      console.error("No scoring feedbacks available or the data is not in the expected format.");
    }
  }
  

  submitScoringFeedback(sessionId: string, scoring: number, suggestions: any[]): void {
    this.assistantManagerService.saveScoringFeedback(sessionId, scoring, suggestions).subscribe({
        next: (response) => {
            console.log('Scoring feedback saved successfully', response);
            this.outlineComponent.isReloadingSuggestions = false;
        },
        error: (error) => {
            console.error('Failed to save scoring feedback', error);
        }
    });
  }

  submitContentOutine(sessionId: string,  contentOutline: any[]): void {
    this.assistantManagerService.saveContentOutline(sessionId, contentOutline).subscribe({
        next: (response) => {
            console.log('Content Outline saved successfully', response);
        },
        error: (error) => {
            console.error('Failed to save content outline', error);
        }
    });
  }

  // assigning value to variable so as not to use the standard assistant and not load calls to get outline and scoring
  handleIsPreviousBrief(isPrevious: boolean) {
    this.isPreviousBrief = isPrevious; 
  }

  handleReturnClick(): void {
    this.showPreSelectionsBrief = true;
    this.nextStepsOpen = false;
    this.displayAdditionalContent = true;
    this.chatBotComponent.isFirstResponse = true;
    this.chatBotComponent.showScrollDownButton = false;
    this.assistantManagerService.closeConnection();
    this.spinnerHandler.handleRequest('minus');
    console.log(" handleReturnClick - ShowPreselection",this.showPreSelectionsBrief," handleReturnClick - NextSteps",this.nextStepsOpen, " handleReturnClick - displayAdditionalContent", this.displayAdditionalContent);
  }

  handleLoadingText(text: string) {
    console.log("handleLoadingText");
    this.loadingText = text;
    this.spinnerHandler.handleRequest('plus');
  }
}
