import {Component, OnInit, PLATFORM_ID, afterNextRender, inject, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, Input, SimpleChanges} from '@angular/core';
import {CommonModule, isPlatformBrowser} from '@angular/common';
import {FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field';;
import { MatMenuModule } from '@angular/material/menu';
import Swal from 'sweetalert2';

import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { HttpHeaders } from '@angular/common/http';
import { Session } from '../models/briefs-sessions-model';

import { AssistantManagerService } from '../services/assistant-manager-service.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-chat-panel',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, FormsModule, MatIconModule,MatTooltipModule,MatButtonModule, MatFormFieldModule, MatInputModule, MatMenuModule ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './chat-panel.component.html',
  styleUrl: './chat-panel.component.sass'
})
export class ChatPanelComponent {

  @Input() profileSlug: string;
  @Input() showContent: boolean = true;
  @Output() chatHistoryLoaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() scoringFeedbacksLoaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() contentOutlineLoaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() sessionIdLoaded: EventEmitter<string> = new EventEmitter<string>();
  @Output() highlightedSessionsLoaded: EventEmitter<Session[]> = new EventEmitter<Session[]>();
  @Output() returnInitiated = new EventEmitter<void>();
  @Output() newChatInitiated: EventEmitter<string> = new EventEmitter<string>();
  frontendServURL = environment.frontendServURL;
  platformId = inject(PLATFORM_ID);
  chatService = inject(AssistantManagerService);
  userId: string = 'userID_1';
  startSessions: Session[] = [];
  filteredSessions: Session[] = [];
  todaySessions: Session[] = [];
  last7DaysSessions: Session[] = [];
  last30DaysSessions: Session[] = [];
  olderThan30DaysSessions: Session[] = [];
  searchText: string = '';
  panelVisible: boolean = true;
  highlightedSessions: Session[] = [];
  showReturnButton: boolean;
  private subscription: Subscription = new Subscription();


  constructor(private cd: ChangeDetectorRef,) { }

  ngOnInit(): void {
    this.initializeComponent();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showContent']) {
      this.panelVisible = this.showContent;
      //console.log("Panel Component", this.showContent);
      if (!this.showContent) {
        this.loadSessions(this.profileSlug);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initializeComponent(): void {
    this.subscription.add(
      this.chatService.profileSlug$.subscribe(slug => {
        if (slug) {
          this.profileSlug = slug;
          this.loadSessions(this.profileSlug);
          this.cd.markForCheck();
        }
      })
    );
  }

  loadSessions(profileSlug: string): void {
    this.chatService.getProfileSessions(profileSlug).subscribe({
      next: (data) => {

        if (data.today && data.last7Days && data.last30Days && data.olderThan30Days) {
          this.todaySessions = data.today;
          this.last7DaysSessions = data.last7Days;
          this.last30DaysSessions = data.last30Days;
          this.olderThan30DaysSessions = data.olderThan30Days;

          // update startSessions with all sessions
          this.startSessions = [...this.todaySessions, ...this.last7DaysSessions, ...this.last30DaysSessions, ...this.olderThan30DaysSessions];
          this.filteredSessions = this.startSessions.filter(session => session.custom_name && session.custom_name.trim() !== '');
          this.prepareHighlightedSessions();
        } else {
          console.warn("wrong data:", data);
        }

        this.cd.markForCheck();
      },
      error: (error) => console.error('Error fetching sessions:', error)
    });
  }
  
  
  filterMessages(): void {
    if (!this.searchText) {
      this.filteredSessions = [...this.startSessions];
    } else {
      this.filteredSessions = this.startSessions.filter(session => {
        const searchLower = this.searchText.toLowerCase();
        const dateMatch = session.created_at.toLowerCase().includes(searchLower);
        const nameMatch = session.custom_name ? session.custom_name.toLowerCase().includes(searchLower) : false;
        return dateMatch || nameMatch;
      });
    }
    this.categorizeSessions(this.filteredSessions);
    this.cd.markForCheck();
  }
  
  // function to categorize sessions
  categorizeSessions(sessions: Session[]): void {
    const now = new Date();
    this.todaySessions = [];
    this.last7DaysSessions = [];
    this.last30DaysSessions = [];
  
    for (const session of sessions) {
      const sessionDate = new Date(session.created_at);
      const diffDays = Math.floor((now.getTime() - sessionDate.getTime()) / (1000 * 3600 * 24));
  
      if (diffDays === 0) {
        this.todaySessions.push(session);
      } else if (diffDays <= 7) {
        this.last7DaysSessions.push(session);
      } else if (diffDays <= 30) {
        this.last30DaysSessions.push(session);
      }
    }
  }
  
  loadChatHistory(sessionId: string): void {
    this.chatService.getChatHistory(sessionId).subscribe({
      next: (response) => {
        if (response.passed) {
          this.chatHistoryLoaded.emit(response.response);
        }
      },
      error: (error) => console.error('Error fetching chat history:', error)
    });
  }

  loadScoringFeedbacks(sessionId: string): void {
    this.chatService.getScoringFeedbacks(sessionId).subscribe({
      next: (response) => {
        if (response.passed) {
          this.scoringFeedbacksLoaded.emit(response.response);
        }
      },
      error: (error) => console.error('Error fetching chat history:', error)
    });
  }

  loadPreviousBriefData(sessionId: string): void {
    console.log('Starting data load for session:', sessionId);
    this.loadChatHistory(sessionId);
    setTimeout(() => {
      this.loadScoringFeedbacks(sessionId);
    }, 100);  // Delay para garantir que não haja conflito
  }
  

  renameSession(sessionId: string): void {
    Swal.fire({
      input: 'text',
      inputLabel: 'New session name',
      inputPlaceholder: 'Type the new name here',
      showCancelButton: true,
      confirmButtonText: 'Rename',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
      customClass: {
        inputLabel: 'bold-label'
      },
      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!';
        }
        return null;
      }
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        // call service to change session name
        this.chatService.updateSessionName(sessionId, result.value).subscribe({
          next: (response) => {
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Session name has been updated successfully.',
              confirmButtonText: 'OK',
              timer: 1500
            });
            this.loadSessions(this.profileSlug);
          },
          error: (error) => {
            console.error('Failed to update session name', error);
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'Failed to update session name.',
              confirmButtonText: 'OK',
              timer: 1500
            });
          }
        });
      }
    });
  }  
  
  deleteSession(sessionId: string): void {
    // show confirmation alert
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this session? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        // user confirmed the action, delete the session
        this.chatService.deleteSession(sessionId).subscribe({
          next: (response) => {
            // display success message
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: 'The session has been deleted.',
              confirmButtonText: 'OK',
              timer: 1500
            });
            // update session list
            this.loadSessions(this.profileSlug);
          },
          error: (error) => {
            // display error message
            console.error('Error deleting session:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'Failed to delete the session. Please try again later.',
              confirmButtonText: 'OK'
            });
          }
        });
      }
    });
  }

  togglePanelVisibility(): void {
    this.panelVisible = !this.panelVisible;
  }

  toggleFavorite(sessionId: string, isCurrentlyFavorite: boolean): void {
    const newFavoriteStatus = !isCurrentlyFavorite;
    this.chatService.setFavorite(sessionId, newFavoriteStatus).subscribe({
      next: (response) => {
        this.loadSessions(this.profileSlug);  // reload sessions to reflect the change
      },
      error: (error) => console.error('Error updating favorite status:', error)
    });
  }

  prepareHighlightedSessions(): void {
    // filter favorite and unfavorite sessions separately
    const favorites = this.startSessions.filter(session => session.is_favorite);
    const nonFavorites = this.startSessions.filter(session => !session.is_favorite);
  
    // check if there are 5 or more favorites
    if (favorites.length >= 5) {
      this.highlightedSessions = favorites.slice(0, 5);
    } else {
      // combine favorites with non-favorites to make 5 if necessary
      this.highlightedSessions = [...favorites, ...nonFavorites.slice(0, 5 - favorites.length)];
    }
    this.highlightedSessionsLoaded.emit(this.highlightedSessions);
  }  

  loadSessionData(sessionId: string): void {
    this.chatService.loadSessionData(sessionId).subscribe({
      next: (data) => {
        if (data.chatHistory.passed) {
          this.chatHistoryLoaded.emit(data.chatHistory.response);
        }
        if (data.scoringFeedbacks.passed) {
          this.scoringFeedbacksLoaded.emit(data.scoringFeedbacks.response);
        }
        if (data.contentOutline.passed) {
          this.contentOutlineLoaded.emit(data.contentOutline.response.outlines[0].outline_html);
        }

        // emit sessionId
        this.sessionIdLoaded.emit(sessionId);
      },
      error: (error) => console.error('Error fetching combined session data:', error)
    });
  }


  returnBriefManagment(){
    //TO-DO implment logic to return
    console.log("returnBriefManagment");
    this.returnInitiated.emit();
  }

  startNewChat() {
    this.newChatInitiated.emit('bb3');
  }
  
  
}
