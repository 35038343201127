<div [ngClass]="{'chat-panel': panelVisible, 'panel-container': !panelVisible}">
  <div [ngClass]="{'panel-controls': panelVisible, 'panel-controls-minimized': !panelVisible}">

    <button mat-icon-button [matTooltip]="panelVisible ? 'Hide panel' : 'Show panel'" (click)="togglePanelVisibility()">
      <mat-icon  class="panel-icons">{{ panelVisible ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
    </button>    
  
    <button *ngIf="!showContent" mat-icon-button matTooltip="Return" (click)="returnBriefManagment()">
      <mat-icon class="panel-icons">undo</mat-icon> 
    </button>
  
    <button class="ms-2" mat-icon-button matTooltip="New brief" (click)="startNewChat()">
      <mat-icon class="panel-icons">chat</mat-icon>
    </button>
  </div>
  
  <div *ngIf="panelVisible" class="panel-content">
    <div class="panel-header">
      <mat-form-field appearance="fill">
        <mat-label>Search Briefs</mat-label>
        <input matInput placeholder="Type to search..." [(ngModel)]="searchText" (keyup)="filterMessages()">
      </mat-form-field>
    </div>
    <div class="panel-title">Previous Briefs</div>
    <div *ngIf="todaySessions.length > 0">
      <div class="date-section-title">Today</div>
      <ul class="messages-list">
        <li *ngFor="let session of todaySessions" (click)="loadSessionData(session.id)">
          <div class="session-content">
            <mat-icon *ngIf="session.is_favorite; else placeholder" class="favorite-icon">star</mat-icon>
            <ng-template #placeholder><span class="placeholder-icon"></span></ng-template>
            <span class="session-info">
              <ng-container *ngIf="session.custom_name; else createdAt">{{ session.custom_name }}</ng-container>
              <ng-template #createdAt>{{ session.created_at | date:'MM/dd/yyyy HH:mm' }}</ng-template>
            </span>
          </div>
          <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="renameSession(session.id)">
              <mat-icon>edit</mat-icon>
              <span>Rename</span>
            </button>
            <button mat-menu-item (click)="deleteSession(session.id)">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
            <button mat-menu-item (click)="toggleFavorite(session.id, session.is_favorite)">
              <mat-icon *ngIf="session.is_favorite; else nonFavoriteIcon">star</mat-icon>
              <ng-template #nonFavoriteIcon><mat-icon>star_border</mat-icon></ng-template>
              <span *ngIf="session.is_favorite; else markFavorite">{{ 'Unmark as Favorite' }}</span>
              <ng-template #markFavorite>{{ 'Mark as Favorite' }}</ng-template>
            </button>
          </mat-menu>
        </li>
      </ul>      
    </div>
    <div *ngIf="last7DaysSessions.length > 0">
      <div class="date-section-title">Last 7 Days</div>
      <ul class="messages-list">
        <li *ngFor="let session of last7DaysSessions" (click)="loadSessionData(session.id)">
          <div class="session-content">
            <mat-icon *ngIf="session.is_favorite; else placeholder" class="favorite-icon">star</mat-icon>
            <ng-template #placeholder><span class="placeholder-icon"></span></ng-template>
            <span class="session-info">
              <ng-container *ngIf="session.custom_name; else createdAt">{{ session.custom_name }}</ng-container>
              <ng-template #createdAt>{{ session.created_at | date:'MM/dd/yyyy HH:mm' }}</ng-template>
            </span>
          </div>
          <button class="session-options" mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="renameSession(session.id)">
              <mat-icon>edit</mat-icon>
              <span>Rename</span>
            </button>
            <button mat-menu-item (click)="deleteSession(session.id)">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
            <button mat-menu-item (click)="toggleFavorite(session.id, session.is_favorite)">
              <mat-icon *ngIf="session.is_favorite; else nonFavoriteIcon">star</mat-icon>
              <ng-template #nonFavoriteIcon><mat-icon>star_border</mat-icon></ng-template>
              <span *ngIf="session.is_favorite; else markFavorite">{{ 'Unmark as Favorite' }}</span>
              <ng-template #markFavorite>{{ 'Mark as Favorite' }}</ng-template>
            </button>
          </mat-menu>          
        </li>
      </ul>
    </div>
    <div *ngIf="last30DaysSessions.length > 0">
      <div class="date-section-title">Last 30 Days</div>
      <ul class="messages-list">
        <li *ngFor="let session of last30DaysSessions" (click)="loadSessionData(session.id)">
          <div class="session-content">
            <mat-icon *ngIf="session.is_favorite; else placeholder" class="favorite-icon">star</mat-icon>
            <ng-template #placeholder><span class="placeholder-icon"></span></ng-template>
            <span class="session-info">
              <ng-container *ngIf="session.custom_name; else createdAt">{{ session.custom_name }}</ng-container>
              <ng-template #createdAt>{{ session.created_at | date:'MM/dd/yyyy HH:mm' }}</ng-template>
            </span>
          </div>
          <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="renameSession(session.id)">
              <mat-icon>edit</mat-icon>
              <span>Rename</span>
            </button>
            <button mat-menu-item (click)="deleteSession(session.id)">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
            <button mat-menu-item (click)="toggleFavorite(session.id, session.is_favorite)">
              <mat-icon *ngIf="session.is_favorite; else nonFavoriteIcon">star</mat-icon>
              <ng-template #nonFavoriteIcon><mat-icon>star_border</mat-icon></ng-template>
              <span *ngIf="session.is_favorite; else markFavorite">{{ 'Unmark as Favorite' }}</span>
              <ng-template #markFavorite>{{ 'Mark as Favorite' }}</ng-template>
            </button>
          </mat-menu>          
        </li>
      </ul>
    </div>
    <!-- more then 30 days -->
    <div *ngIf="olderThan30DaysSessions.length > 0">
      <div class="date-section-title">Older (30+ Days)</div>
      <ul class="messages-list">
        <li *ngFor="let session of olderThan30DaysSessions" (click)="loadSessionData(session.id)">
          <div class="session-content">
            <mat-icon *ngIf="session.is_favorite; else placeholder" class="favorite-icon">star</mat-icon>
            <ng-template #placeholder><span class="placeholder-icon"></span></ng-template>
            <span class="session-info">
              <ng-container *ngIf="session.custom_name; else createdAt">{{ session.custom_name }}</ng-container>
              <ng-template #createdAt>{{ session.created_at | date:'MM/dd/yyyy HH:mm' }}</ng-template>
            </span>
          </div>
          <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="renameSession(session.id)">
              <mat-icon>edit</mat-icon>
              <span>Rename</span>
            </button>
            <button mat-menu-item (click)="deleteSession(session.id)">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
            <button mat-menu-item (click)="toggleFavorite(session.id, session.is_favorite)">
              <mat-icon *ngIf="session.is_favorite; else nonFavoriteIcon">star</mat-icon>
              <ng-template #nonFavoriteIcon><mat-icon>star_border</mat-icon></ng-template>
              <span *ngIf="session.is_favorite; else markFavorite">{{ 'Unmark as Favorite' }}</span>
              <ng-template #markFavorite>{{ 'Mark as Favorite' }}</ng-template>
            </button>
          </mat-menu>          
        </li>
      </ul>
    </div>
  </div>
</div>